import "./Footer.scss";
import { images } from "../../utils/images";

import TagLine from "./tagline/TagLine";
import SquareBtn from "../../components/squareBtn/SquareBtn";
import { motion } from "framer-motion";
import { Fragment } from "react";
import { NavLink } from "react-router-dom";
import { FaLinkedinIn } from "react-icons/fa";
import useWindowWidth from "../../utils/hooks/useWidth";
import { paths } from "../../utils/paths";

const Footer = () => {
  const goToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const width = useWindowWidth();

  const routing = [
    {
      id: 0,
      text: "Home",
      path: paths.HOME,
    },
    {
      id: 1,
      text: "About",
      path: paths.ABOUT,
    },
   
    {
      id: 3,
      text: "Work",
      path: paths.WORK,
    },
    {
      id: 2,
      text: "Blog",
      path: paths.BLOGS,
    },
    {
      id: 4,
      text: "Contact",
      path: paths.CONTACT,
    },
    {
      id: 5,
      text: "Journal",
      path: paths.JOURNAL,
    },
  ];

  return (
    <footer>
      <section className="footer p_t">
        <div className="content_wrap">
          <div className="grid_line">
            <div className="info">
              <img src={images.logo} alt="Brand Logo" />
              <a href="mailto:contact@motleycrew.in" target="_blank">
                <SquareBtn theme="dark" title="contact@motleycrew.in" />
              </a>
            </div>
            <div className="link_grid">
              <div className="link_col">
                <h6>Quik Links</h6>
                {routing.map((data) => (
                  <Fragment key={data.id}>
                    <NavLink to={data.path}>{data.text}</NavLink>
                  </Fragment>
                ))}
              </div>

              <div className="link_col address">
                <h6>Social Media</h6>

                <a
                  href="https://www.linkedin.com/company/motleycrew/"
                  target="_blank"
                >
                  <FaLinkedinIn /> Linkedin
                </a>
                <h6 className="pt-2">Address</h6>
                <p>
                  5th Block,
                  <br />
                  Koramangala Industrial Area,
                  <br />
                  JNC Road,
                  <br />
                  Bengaluru-94, KA
                </p>
              </div>
            </div>

            <div className="iframe_wrap">
              <iframe
                src="https://hasitakrishna.substack.com/embed"
                width="100%"
                height={width > 450 ? "450px" : "600px"}
              ></iframe>
            </div>
          </div>
          <TagLine />
          <div className="bottom_footer">
            <p>
              © {new Date().getFullYear()} Motley Crew. Designed and developed
              by{" "}
              <a href="https://supagrow.in/" target="_blank">
                <b>Supagrow</b>
              </a>
              . All rights reserved
            </p>
            <motion.div
              initial={{ translateY: 24, opacity: 0 }}
              whileInView={{ translateY: 0, opacity: 1 }}
              transition={{
                duration: 0.6,
                ease: "easeInOut",
              }}
              viewport={{ once: true }}
            >
              <SquareBtn title="Top" onClick={goToTop} />
            </motion.div>
          </div>
        </div>
      </section>
    </footer>
  );
};

export default Footer;
