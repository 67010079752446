export const baseUrl = "https://motleycrew.supagrow.in";

export const contact = "api/storecontactapi";
// Work Page
export const work = "api/getworks";
export const workDetail = "api/workdetails";
export const homePage = "api/homepageapi";
export const about = "api/aboutpageapi";
export const getClient = "api/getclient";
export const getCategory = "api/getcategory";
export const getCta = "api/getcta";
export const getSubstack = "api/getrss";
export const getBlogs = "api/getblogs";
export const getBlogDetail = "api/blogdetails";
export const emailSubscribe = "api/storenewsapi";