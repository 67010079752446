import axios from "axios";
import WebLayout from "../../layouts/weblayout/WebLayout";
import { baseUrl, getBlogDetail } from "../../utils/apiData";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import BlogInfo from "./blogInfo/BlogInfo";
import { Helmet } from "react-helmet";
const BlogDetail = () => {
  const { slug } = useParams();
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState({});

  const getBlogDetailApi = async () => {
    setLoader(true);
    try {
      const res = await axios.post(`${baseUrl}/${getBlogDetail}`, { slug });
      if (res?.data?.success) {
        setData(res?.data?.data);
      }
    } catch (error) {
      console.log(error);
    }
    setLoader(false);
  };

  useEffect(() => {
    getBlogDetailApi();
  }, []);

  return (
    <WebLayout page="home" isCta={true}>
      <Helmet>
        <title>{`${data?.name} | Motley Crew`}</title>
        <meta name="description" content="Home | Motley Crew" />
      </Helmet>
      <BlogInfo data={data} />
    </WebLayout>
  );
};

export default BlogDetail;
