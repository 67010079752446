import WebLayout from "../../layouts/weblayout/WebLayout";
import ContactForm from "./contactForm/ContactForm";
import {Helmet} from "react-helmet";

const Contact = () => {
  return (
    <WebLayout page="contact">
        <Helmet>
        <title>Contact | Motley Crew</title>
        <meta name="description" content="Home | Motley Crew" />
      </Helmet>
      <ContactForm />
    </WebLayout>
  );
};

export default Contact;
