import "./CaseStudyDetails.scss";
import WebLayout from "../../layouts/weblayout/WebLayout";
import CaseLanding from "./caseLanding/CaseLanding";
import CaseSummary from "./caseSummary/CaseSummary";
import { useParams } from "react-router-dom";
import axios from "axios";
import { baseUrl, workDetail } from "../../utils/apiData";
import { Fragment, useEffect, useState } from "react";
import Loader from "../../components/loader/Loader";
import {Helmet} from "react-helmet";

const CaseStudyDetails = () => {
  const { slug } = useParams();

  const [data, setData] = useState();
  const [loader, setLoader] = useState(false);

  const getDetailApi = async () => {
    setLoader(true);
    try {
      const res = await axios.post(`${baseUrl}/${workDetail}`, { slug });
      if (res?.data?.success) {
        setData(res?.data?.data);
      }
    } catch (error) {
      console.log(error);
    }
    setLoader(false);
  };

  useEffect(() => {
    getDetailApi();
  }, []);

  return (
    <WebLayout>
          <Helmet>
        <title>{`${data?.name} | Motley Crew`}</title>
        <meta name="description" content="Home | Motley Crew" />
      </Helmet>
      {loader ? (
        <Loader />
      ) : (
        <Fragment>
          <CaseLanding data={data} />
          <CaseSummary data={data} />
        </Fragment>
      )}
    </WebLayout>
  );
};

export default CaseStudyDetails;
