import axios from "axios";
import WebLayout from "../../layouts/weblayout/WebLayout";
import AboutLanding from "./aboutLanding/AboutLanding";
import CenterText from "./centerText/CenterText";
import Team from "./team/Team";
import Values from "./values/Values";
import { about, baseUrl } from "../../utils/apiData";
import { Fragment, useEffect, useState } from "react";
import Loader from "../../components/loader/Loader";
import { Helmet } from "react-helmet";
const About = () => {
  const [aboutData, setAboutData] = useState({});
  const [loader, setLoader] = useState(false);
  const [team, setTeam] = useState([]);

  const aboutApi = async () => {
    setLoader(true);
    try {
      const res = await axios.get(`${baseUrl}/${about}`);
      if (res?.data?.success) {
        setAboutData(res?.data?.data?.aboutPage);
        setTeam(res?.data?.data?.team);
      }
    } catch (error) {
      console.log(error);
    }
    setLoader(false);
  };

  useEffect(() => {
    aboutApi();
  }, []);

  return (
    <Fragment>
    <Helmet>
    <title>About | Motley Crew</title>
    <meta name="description" content="Home | Motley Crew" />
  </Helmet>
    <WebLayout page="about" isCta={true}>
      {loader ? (
        <Loader />
      ) : (
        <Fragment>
          <AboutLanding data={aboutData} />
          <CenterText data={aboutData} />
          <Values data={aboutData} />
          <Team
            team={team}
            title={aboutData?.team_title}
            tag={aboutData?.team_heading}
          />
        </Fragment>
      )}
    </WebLayout>
    </Fragment>
  );
};

export default About;
