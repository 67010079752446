import WebLayout from "../../layouts/weblayout/WebLayout";
import { motion } from "framer-motion";
import "./Blogs.scss";
import { HiArrowUpRight } from "react-icons/hi2";
import { baseUrl, getBlogs } from "../../utils/apiData";
import { Fragment, useEffect, useState } from "react";
import axios from "axios";
import Loader from "../../components/loader/Loader";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

const Blogs = () => {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [blogList, setBlogList] = useState([]);

  const getBlogListApi = async () => {
    setLoader(true);
    try {
      const res = await axios.get(`${baseUrl}/${getBlogs}`);
      if (res?.data?.success) {
        setBlogList(res?.data?.data);
      }
    } catch (error) {
      console.log(error);
    }
    setLoader(false);
  };

  useEffect(() => {
    getBlogListApi();
  }, []);

  return (
    <WebLayout page="home" isCta={true}>
      <Helmet>
        <title>Blog | Motley Crew</title>
        <meta name="description" content="Home | Motley Crew" />
      </Helmet>
      {loader ? (
        <Loader />
      ) : (
        <section className="blog_sec p_t p_b">
          <div className="content_wrap">
            <div className="title_wrap">
              <motion.h1
                initial={{ translateY: 24, opacity: 0 }}
                whileInView={{ translateY: 0, opacity: 1 }}
                transition={{ duration: 0.6, ease: "easeInOut" }}
                viewport={{ once: true }}
              >
                Blogs
              </motion.h1>
              <motion.p
                initial={{ translateY: 24, opacity: 0 }}
                whileInView={{ translateY: 0, opacity: 1 }}
                transition={{ duration: 0.6, ease: "easeInOut", delay: 0.2 }}
                viewport={{ once: true }}
              >
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse
                beatae voluptatum odit. Lorem ipsum dolor sit amet consectetur,
                adipisicing elit.
              </motion.p>
            </div>

            {blogList?.length > 0 ? (
              <div className="blog_list">
                {blogList?.map((data) => {
                  return (
                    <div
                      className="blog_card"
                      key={data?._id}
                      onClick={() => navigate(`/blog/${data?.slug}`)}
                    >
                      <div className="img_wrap">
                        <img
                          src={`${baseUrl}/${data?.image}`}
                          alt="Blog Image"
                        />
                      </div>

                      <div className="text_grid">
                        <h5>{data?.name}</h5>
                        <p>{data?.small_description}</p>
                      </div>

                      <button type="button" className="arrow_btn">
                        <HiArrowUpRight />
                      </button>
                      <button type="button" className="read_more">
                        Read More
                      </button>
                    </div>
                  );
                })}
              </div>
            ) : (
              <p className="text-center">Data not found...</p>
            )}
          </div>
        </section>
      )}
    </WebLayout>
  );
};

export default Blogs;
