import WebLayout from "../../layouts/weblayout/WebLayout";
import WorkList from "./workList/WorkList";
import {Helmet} from "react-helmet";

const Work = () => {
  return (
    <WebLayout page="work" isCta={true}>
          <Helmet>
        <title>Work | Motley Crew</title>
        <meta name="description" content="Home | Motley Crew" />
      </Helmet>
      <WorkList />
    </WebLayout>
  );
};

export default Work;
