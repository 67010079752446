export const paths = {
  HOME: "/",
  ABOUT: "/about",
  WORK: "/work",
  CONTACT: "/contact",
  CASEDETAI: "/casestudy/:slug",
  JOURNAL: "/journal",
  PRIVACYPOLICY: "/privacy_policy",
  TERMSCONDTION: "/terms&condition",
  BLOGS:"/blog",
  BLOGDETAIL:"/blog/:slug"
};
