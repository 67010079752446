import "./App.scss";
import { Route, Routes, useLocation } from "react-router-dom";
import AnimatedCursor from "react-animated-cursor";
import { Fragment, useEffect, useState } from "react";
import { ParallaxProvider } from "react-scroll-parallax";
import PreLoader from "./components/preloader/PreLoader";
import {
  Home,
  About,
  Work,
  Contact,
  Podcast,
  CaseStudyDetails,
  TermsCondition,
  PrivacyPolicy,
  ErrorPage,
  Blogs,
  BlogDetail,
} from "./pages/index";
import { paths } from "./utils/paths";

function App() {
  const location = useLocation();

  const [preLoader, setPreLoader] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    setPreLoader(true);
    const setTimeoutId = setTimeout(() => {
      setPreLoader(false);
    }, 3500);

    return () => clearInterval(setTimeoutId);
  }, []);

  return (
    <Fragment>
      {preLoader ? (
        <PreLoader />
      ) : (
        <ParallaxProvider>
          <div className="pointer_wrap">
            <AnimatedCursor
              innerSize={10}
              outerSize={0}
              color="244, 144, 11"
              outerAlpha={0}
              innerScale={1}
              outerScale={1}
              clickables={[
                "a",
                'input[type="text"]',
                'input[type="email"]',
                'input[type="number"]',
                'input[type="submit"]',
                'input[type="image"]',
                "label[for]",
                "select",
                "textarea",
                "button",
                ".link",
              ]}
            />
          </div>
          <Routes>
            <Route path={paths.HOME} element={<Home />} />
            <Route path={paths.ABOUT} element={<About />} />
            <Route path={paths.WORK} element={<Work />} />
            <Route path={paths.CONTACT} element={<Contact />} />
            <Route path={paths.CASEDETAI} element={<CaseStudyDetails />} />
            <Route path={paths.JOURNAL} element={<Podcast />} />
            <Route path={paths.BLOGS} element={<Blogs />} />
            <Route path={paths.BLOGDETAIL} element={<BlogDetail />} />
            <Route path="/*" element={<ErrorPage />} />
          </Routes>
        </ParallaxProvider>
      )}
    </Fragment>
  );
}

export default App;
