import { Fragment, useEffect, useState } from "react";
import "./WorkList.scss";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { baseUrl, work, getCategory } from "../../../utils/apiData";
import axios from "axios";
import Loader from "../../../components/loader/Loader";
import CaseStudyCard from "./caseStudyCard/CaseStudyCard";

const WorkList = () => {
  const [select, setSelect] = useState("");

  const [recall, setRecall] = useState(1);

  const [caseList, setCaseList] = useState([]);
  const [loader, setLoader] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const [titleData, setTitleData] = useState({});

  // get Category API
  const getCategoryApi = async () => {
    setLoader(true);
    try {
      const res = await axios.get(`${baseUrl}/${getCategory}`);
      if (res?.data?.success) {
        setCategoryList(res?.data?.data);
      }
    } catch (error) {
      console.log(error);
    }
    setLoader(false);
  };

  // getCaseList Api
  const getCaseListApi = async () => {
    setLoader(true);
    try {
      const res = await axios.post(`${baseUrl}/${work}`, {
        category_id: `${select}`,
      });
      if (res?.data?.success) {
        setCaseList(res?.data?.data?.blogsdata);
        setTitleData(res?.data?.data?.sectionData);
      }
    } catch (error) {
      console.log(error);
    }
    setLoader(false);
  };

  useEffect(() => {
    getCategoryApi();
  }, []);

  useEffect(() => {
    getCaseListApi();
  }, [recall]);

  return (
    <section className="work p_top p_b">
      <div className="content_wrap">
        {loader ? (
          <Loader />
        ) : (
          <Fragment>
            <div className="title_wrap">
              <motion.h1
                initial={{ translateY: 24, opacity: 0 }}
                whileInView={{ translateY: 0, opacity: 1 }}
                transition={{ duration: 0.6, ease: "easeInOut" }}
                viewport={{ once: true }}
              >
                {titleData?.work_titles || "-"}
              </motion.h1>
              <motion.p
                initial={{ translateY: 24, opacity: 0 }}
                whileInView={{ translateY: 0, opacity: 1 }}
                transition={{ duration: 0.6, ease: "easeInOut", delay: 0.2 }}
                viewport={{ once: true }}
              >
                {titleData?.work_descriptions || "-"}
              </motion.p>
              <div className="category_wrap">
                <motion.button
                  initial={{ translateX: 24, opacity: 0 }}
                  whileInView={{ translateX: 0, opacity: 1 }}
                  transition={{
                    duration: 0.6,
                    ease: "easeInOut",
                    delay: 0.1,
                  }}
                  viewport={{ once: true }}
                  type="button"
                  className={!select ? "active" : ""}
                  onClick={() => {
                    setSelect("");
                    setRecall((prev) => prev + 1);
                  }}
                >
                  All
                </motion.button>
                {categoryList.map((data, j) => {
                  return (
                    <Fragment key={data._id}>
                      <motion.button
                        initial={{ translateX: 24, opacity: 0 }}
                        whileInView={{ translateX: 0, opacity: 1 }}
                        transition={{
                          duration: 0.6,
                          ease: "easeInOut",
                          delay: 0.1 * j,
                        }}
                        viewport={{ once: true }}
                        type="button"
                        className={select === data._id ? "active" : ""}
                        onClick={() => {
                          setSelect(data._id);
                          setRecall((prev) => prev + 1);
                        }}
                      >
                        {data.name}
                      </motion.button>
                    </Fragment>
                  );
                })}
              </div>
            </div>

            {caseList?.length > 0 ? (
              caseList?.map((data) => {
                return (
                  <Fragment key={data?._id}>
                    <CaseStudyCard data={data} />
                  </Fragment>
                );
              })
            ) : (
              <p className="text-center">Data not found...</p>
            )}
          </Fragment>
        )}
      </div>
    </section>
  );
};

export default WorkList;
