import { useState } from "react";
import { baseUrl, emailSubscribe } from "../../../utils/apiData";

import "./BlogInfo.scss";
import axios from "axios";

const BlogInfo = ({ data }) => {
  const formatDate = (dateString) => {
    const date = new Date(dateString);

    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "long" });
    const year = date.getFullYear();

    return `${day} ${month} ${year}`;
  };

  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [loader, setLoader] = useState(false);

  const inputChange = (e) => {
    const value = e.target.value;
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (value.trim().length === 0) {
      setError("This is a required field.");
    } else if (!emailPattern.test(value)) {
      setError("Invalid email format!");
    } else {
      setError("");
    }
    setEmail(value);
  };


  // Email Subscribe API

  const emailSubscribeApi = async () => {
    setLoader(true);
    try {
      const res = await axios.post(`${baseUrl}/${emailSubscribe}`, {email});
      if (res?.data?.success) {
        setSuccess(true);
        setEmail("");
        window.scrollTo({ top: 0, behavior: "smooth" });
      }
    } catch (error) {
      console.log(error);
    }
    setLoader(false);
  };


  const formSubmit = (e) => {
    e.preventDefault();
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    e.preventDefault();
    if (!email) {
      setError("This is a required field.");
    } else if (!emailPattern.test(email)) {
      setError("Invalid email format!");
    } else {
      setError("");
      console.log(email);
      emailSubscribeApi();
    }
  };

  return (
    <section className="blog_info p_t p_b">
      <div className="content_wrap">
        <div className="top_title">
          <div className="left">
            <h3>{data?.name}</h3>
            <p>{data?.small_description}</p>
          </div>
          <div className="right">
            <p>{formatDate(data?.createdAt)}</p>
          </div>
        </div>
        <div className="blog_detail_grid">
          <div className="email_subscription">
            <h4>Subscribe our lorem ipsum amet</h4>
            <p>
              Lorem ipsum dolor sit amet consectetur, adipisicing elit. Est
              reprehenderit.
            </p>
            {success ? (
              <p className="success">Form submited successfully!</p>
            ) : (
              <form onSubmit={formSubmit}>
                <div className="field_wrap">
                  <label>Email</label>
                  <input
                    className="mail"
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={inputChange}
                  />
                  {error && <p className="error">{error}</p>}
                  <button type="submit">
                    {loader ? "Loading..." : "Submit"}
                  </button>
                </div>
              </form>
            )}
          </div>
          <div className="blog_detail_info">
            <div className="img_wrap">
              <img src={`${baseUrl}/${data?.image}`} alt="Blog Image" />
            </div>
            <div dangerouslySetInnerHTML={{ __html: data?.content }}></div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BlogInfo;
