import { Fragment, useState } from "react";
import HomeLanding from "./homeLanding/HomeLanding";
import WhoWe from "./whoWe/WhoWe";
import CaseStudies from "./caseStudies/CaseStudies";
import Faq from "./faq/Faq";
import Expertise from "./expertise/Expertise";
import WebLayout from "../../layouts/weblayout/WebLayout";
import LogoSlider from "./logoSlider/LogoSlider";
import axios from "axios";
import { baseUrl, homePage } from "../../utils/apiData";
import Loader from "../../components/loader/Loader";
import { Helmet } from "react-helmet";
const Home = () => {
  const [loader, setLoder] = useState(false);
  const [homeData, setHomeData] = useState({});
  const [expertice, setExpertise] = useState([]);
  const [faq, setFaq] = useState([]);

  // Home Page Api
  const homePageApi = async () => {
    setLoder(true);
    try {
      const res = await axios.get(`${baseUrl}/${homePage}`);
      if (res?.data?.success) {
        setHomeData(res?.data?.data?.homepage);
        setExpertise(res?.data?.data?.expertise);
        setFaq(res?.data?.data?.faq);
      }
    } catch (error) {
      console.log(error);
    }
    setLoder(false);
  };

  useState(() => {
    homePageApi();
  }, []);

  return (
    <Fragment>
      <Helmet>
        <title>Home | Motley Crew</title>
        <meta name="description" content="Home | Motley Crew" />
      </Helmet>
      <WebLayout page="home" isCta={true}>
        {loader ? (
          <Loader />
        ) : (
          <Fragment>
            <HomeLanding homeData={homeData} />
            <WhoWe homeData={homeData} />
            <CaseStudies homeData={homeData} />
            <Expertise
              title={homeData?.our_expertise_title}
              lTitle={homeData?.our_expertise_heading}
              rPara={homeData?.our_expertise_description}
              dataList={expertice}
            />
            <LogoSlider />
            <Faq homeData={homeData} data={faq} />
          </Fragment>
        )}
      </WebLayout>
    </Fragment>
  );
};

export default Home;
